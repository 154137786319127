var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nav-top border-b border-neutral-300"
  }, [_c('div', {
    staticClass: "flex justify-between py-4 px-5 bg-white"
  }, [_c('div', {
    staticClass: "w-60 flex gap-4 items-center"
  }, [_c('div', {
    staticClass: "flex items-center gap-12"
  }, [_c('div', {
    staticClass: "cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_c('Hamburger')], 1)])]), !_vm.isMobile ? _c('div', {
    staticClass: "menu flex items-center gap-2"
  }, [_c('div', {}, [_c('User', {
    attrs: {
      "width": "40",
      "height": "40"
    }
  })], 1), _c('div', {
    staticClass: "relative text-left pr-10 cursor-pointer user-item"
  }, [_c('p', {
    staticClass: "text-neutral-700 text-lg font-bold leading-tight"
  }, [_vm._v(_vm._s(_vm.user.name))]), _c('p', {
    staticClass: "text-sm text-neutral-500"
  }, [_vm._v(_vm._s(_vm.user.group))]), _c('span', {
    staticClass: "inline-block absolute top-1/2 leading-0 transform -translate-y-1/2 right-0"
  }, [_c('Chevron', {
    attrs: {
      "width": "16",
      "height": "16",
      "direction": "down",
      "color": "#101840"
    }
  })], 1), _c('div', {
    staticClass: "header__nav__menu-item-submenu invisible opacity-0 ease-linear transition-all duration-300 pt-6 left-2/4 top-full transform -translate-x-2/4 absolute cursor-default z-10"
  }, [_c('div', {
    staticClass: "user-menu bg-white shadow-xl border border-grey-darker rounded relative"
  }, [_c('div', {
    staticClass: "relative z-10 rounded bg-white p-6 text-black"
  }, [_c('div', {}, [_c('div', {
    staticClass: "cursor-pointer text-yellow text-sm",
    on: {
      "click": function ($event) {
        return _vm.doLogout();
      }
    }
  }, [_vm._v("Logout")])])])])])])]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }